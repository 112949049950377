import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import useIsUser from "src/hooks/use-is-user";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard() {
  const { isAuthenticated, user } = useAuth();
  const isUser = useIsUser();
  const isSignedDoc = Boolean(user?.user_profile?.is_signed_document);

  const isSigned = Boolean(
    JSON.parse(localStorage.getItem("isImpersonateSigned"))
  );
  if (isAuthenticated) {
    if (isUser && (isSigned || isSignedDoc)) {
      return <Navigate to={PATH_USER.root} replace />;
    }
    if (!isSigned || !isSignedDoc) {
      return (
        <Navigate
          to={PATH_USER.onlineStore.productSubscription.products.root}
          replace
        />
      );
    }
    if (isUser) {
      return <Navigate to={PATH_USER.root} replace />;
    }

    return <Navigate to={PATH_DASHBOARD.root} replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}
